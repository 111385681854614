maxkir.progress = {
  show_page_loading: function(fast) {
    this._cl('loadingItem').add('in-progress');
    if (fast) {
      this._cl('loadingItem').add('now');
    }
  },

  hide_page_loading: function() {
    this._cl('loadingItem').remove('in-progress', 'now');
  },

  show_progress: function() {
    this._cl('progressItem').add('in-progress');
  },

  hide_progress: function() {
    this._cl('progressItem').remove('in-progress');
  },

  _cl: function(id) {
    var el = document.getElementById(id);
    if (el) {
      return el.classList;
    }
    return {
      add: function(){},
      remove: function() {}
    };
  }
};