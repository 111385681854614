/*
 t("products.price");
 */
/**
 * 
 * Uses window.I18n_data
 * 
 * @return {string}
 */
function I18N(key) {
  if(!key){
    return "N/A";
  }
  if (!key.includes("js_") && maxkir.is_dev()) {
    maxkir.warn("No translation found for key: " + key);
  }

  var comp = window.I18n_data;
  var keys = key.split(".");
  for(var i = 0; i < keys.length; i ++) {
    if(comp){
      comp = comp[keys[i]];
    }
  }

  if(!comp) {
    maxkir.error("No translation found for key: " + key);
    return "Missing text for " + key;
  }

  return comp;
}

window.I18N = I18N;